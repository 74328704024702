import { Box, type SxProps } from '@mui/material'
import { Icon, secondaryColor, type CompoundIconName, type IconSize } from '@orus.eu/pharaoh'
import { memo } from 'react'

type ContainedIconVariant = 'roundedsquare' | 'disc'

type ContainedIconProps = {
  size?: IconSize
  icon: CompoundIconName
  color?: string
  sx?: SxProps
  className?: string
  iconClassName?: string
  variant?: ContainedIconVariant
  backgroundColor?: string
  boxSize?: IconSize
  border?: string
}

const boxSizes: { [key in IconSize]: string } = {
  '5': '16px',
  '10': '24px',
  '20': '28px',
  '30': '32px',
  '40': '48px',
  '50': '56px',
  '60': '80px',
  '70': '128px',
  '80': '160px',
  '90': '192px',
  '100': '224px',
  '110': '256px',
  '120': '288px',
  '130': '320px',
}

const borderRadiuses: { [key in ContainedIconVariant]: string } = {
  disc: '100%',
  roundedsquare: '25%',
}

export const ContainedIcon: React.FunctionComponent<ContainedIconProps> = memo(function ContainedIcon({
  size = '30',
  variant = 'disc',
  icon,
  color = secondaryColor,
  sx,
  className,
  iconClassName,
  backgroundColor,
  boxSize,
  border,
}): JSX.Element {
  const appliedBoxSize = boxSizes[boxSize || size]

  const boxStyle: SxProps = {
    flexShrink: 0,
    width: appliedBoxSize,
    height: appliedBoxSize,
    borderRadius: borderRadiuses[variant],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: backgroundColor || color + '20',
    border,
    ...sx,
  }
  return (
    <Box sx={boxStyle} className={className}>
      <Icon icon={icon} color={color} size={size} className={iconClassName} />
    </Box>
  )
})
